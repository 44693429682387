<template>
  <div class="nav-contian">
    <div class="top-fixed">
      <!--顶部-->
      <div class="top hidden-sm-and-down">
        <el-row type="flex" justify="center">
          <el-col :sm="23" :md="22" :lg="20">
            <div class="m-flex-between-center top-tips-contian">
              <span class="size16 white">欢迎来到济南医发医学检验实验室 —— 全资国有平台 值得信赖</span>
              <div class="m-flex-between-center size16 white">
                <span class="contact tell rel">{{ configData.tell }}</span>
                <span class="contact web rel">{{ configData.email }}</span>
              </div>
            </div>
          </el-col>
        </el-row>
        <img src="../../assets/images/star.png" class="top-star">
      </div>
      <!--    菜单-->
      <div class="top-menu">
        <el-row type="flex" justify="center">
          <el-col :xs="23" :sm="23" :md="22" :lg="20">
            <div class="menu-coontian">
              <img src="../../assets/images/top-logo.png" alt="" class="top-logo" @click="logoHandle">
              <!-- PC端显示-->
              <div class="menu-right m-flex hidden-sm-and-down">
                <el-menu :default-active="$route.path" :activeIndex="currentMenu" router background-color="#ffffff"
                         text-color="#1D2023"
                         active-text-color="#0078E0" class="el-menu-demo" mode="horizontal"
                >
                  <el-menu-item :index="item.path" v-for="(item,index) in menuList" :key="index">
                    <div class="menu-title">
                      {{ item.menuName }}
                    </div>
                  </el-menu-item>
                </el-menu>
                <div class="right-btn">
                  <div class="right-btn-w reservation" @click="serverHandle('Reservation','预约服务')">预约</div>
                  <div class="right-btn-w download" @click="serverHandle('Download','下载报告')">报告下载</div>
                </div>
              </div>
              <!-- 手机端显示-->
              <div class="menu-right hidden-md-and-up">
                <i class="el-icon-s-unfold top-menu-icon" @click="drawer=true"></i>
                <el-dropdown trigger="click">
                  <span class="el-dropdown-link">
                    <i class="el-icon-s-operation top-menu-icon"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item>
                      <div class="right-btn-w-app reservation m-flex-center"
                           @click="serverHandle('Reservation','预约服务')"
                      >预约
                      </div>
                    </el-dropdown-item>
                    <el-dropdown-item>
                      <div class="right-btn-w-app download m-flex-center" @click="serverHandle('Download','下载报告')">
                        报告下载
                      </div>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>

              </div>
              <!-- 手机端显示-->
            </div>
          </el-col>
        </el-row>
      </div>
      <!--  菜单-手机端  -->
      <el-drawer title="导航菜单" :visible.sync="drawer" direction="rtl">
        <el-menu :default-active="$route.path" :activeIndex="currentMenu" router background-color="#ffffff"
                 text-color="#1D2023"
                 active-text-color="#0078E0" class="el-menu-demo" mode="vertical" @select="menuSelect"
        >
          <el-menu-item :index="item.path" v-for="(item,index) in menuList" :key="index">
            <div class="menu-title menu-title-app">
              {{ item.menuName }}
            </div>
          </el-menu-item>
        </el-menu>
      </el-drawer>
    </div>
    <!-- 页面位置信息-->
    <TagsView v-if="showTags"/>
  </div>
</template>

<script>
import TagsView from '@/components/TagsView/index.vue'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'NavBar',
  components: { TagsView },
  data() {
    return {
      menuList: [{
        menuName: '首页',
        path: '/'
      }],
      currentMenu: '/',
      //   是否显示侧边菜单
      drawer: false
    }
  },
  beforeRouteEnter(to) {
    console.log(to, 5)
  },
  computed: {
    ...mapState('tags', ['showTags']),
    ...mapState('config', ['configData'])
  },
  created() {
    // 获取菜单数据
    this.GenerateRoutes().then(topMenu => {
      this.menuList = [...this.menuList, ...topMenu]
    })
  },
  methods: {
    ...mapActions('menu', ['GenerateRoutes']),
    //   预约和报告下载
    serverHandle(currentCom, activeTitle) {
      this.$router.push({ path: '/server', query: { currentCom, activeTitle } }).catch(err => err)
    },
    //   切换导航
    menuSelect() {
      this.drawer = false
    },
    //   点击logo跳转到首页
    logoHandle() {
      this.$router.push({ path: '/' }).catch(err => err)
    }
  }
}
</script>

<style scoped>
.top {
  width: 100vw;
  height: 55px;
  background: url("@/assets/images/top-bg.png") center center no-repeat;
  background-size: cover;
  position: relative;
}

.top-star {
  width: 153px;
  height: 48px;
  position: absolute;
  right: 0;
  top: 0;
}

.top-fixed {
  position: fixed;
  top: 0;
  z-index: 9999;
  width: 100%;
}

.top-tips-contian {
  height: 55px;
}

.contact {
  display: block;
  padding-left: 45px;
}

.contact:before {
  content: "";
  display: block;
  width: 35px;
  height: 35px;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -17px;
}

.tell:before {
  background: url("@/assets/images/tell.png") center center no-repeat;
  background-size: 100%;
}

.web:before {
  background: url("@/assets/images/site.png") center center no-repeat;
  background-size: 100%;
}

.tell {
  margin-right: 40px;
}

.top-menu {
  height: 70px;
  background: rgba(255, 255, 255, 0.9);
  overflow: hidden;
}

.menu-coontian {
  /*width: 100%;*/
  /*margin: 0 auto;*/
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.menu-title {
  font-size: 18px;
}

.menu-title:hover {
  font-weight: bold;
}

.top-logo {
  width: 338px;
  margin-top: 10px;
  cursor: pointer;
}

.right-btn {
  display: flex;
  margin-top: 10px;
}

.right-btn-w {
  width: 120px;
  height: 46px;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
}

.right-btn-w:hover {
  opacity: .7;
}

.reservation {
  background: #0078E0;
  border: 2px #0078E0 solid;
  margin-right: 16px;
  color: #fff;
}

.download {
  border: 2px #0078E0 solid;
  color: #0078E0;
  background: rgba(0, 0, 0, 0);
}

</style>
